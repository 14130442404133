// Snippet to intialize liftigniter
// Tomo gave this to me
// TODO: get key from config?
if (typeof $igniter_var === 'undefined') {
    !(function n(t, c, r, o, a, i, s, e, f, l, u) {
        (l = null != t[r] && 'function' == typeof t[r].now ? t[r].now() : null),
            (t.$igniter_var = i),
            (t[i] =
                t[i] ||
                function () {
                    (t[i].q = t[i].q || []).push(arguments);
                }),
            (e = c.getElementsByTagName(o)[0]),
            ((f = c.createElement(o)).async = 1),
            f.setAttribute('charset', 'UTF-8'),
            '//cdn' == a
                ? ((t[i].s = l),
                  (f.onerror = function (e) {
                      (t[i].e = e), n(t, c, r, o, a + '-fallback', i, s);
                  }))
                : (t[i].r = l),
            (u = !t.Promise || !t.MutationObserver || !Object.assign) ||
                (t[i].v = 'nbc' + (0 <= a.indexOf('-fallback') ? 'f' : '')),
            (f.src =
                a + '.petametrics.com/' + s + (u ? '' : '-nbc') + '.js?ts=' + ((+new Date() / 36e5) | 0)),
            e.parentNode.insertBefore(f, e);
    })(window, document, 'performance', 'script', '//cdn', '$p', '999gba4qphtjrmpe');

    // TODO: remove config when we're ready to cookie users.
    $p('init', '999gba4qphtjrmpe', {
        config: {
            consentManagement: { checkTcfApiFunction: (callback) => callback(false) },
        },
    });
}

function scaleImages(items) {
    items.forEach((item) => {
        let imageUrl = item['thumbnail'];
        let lores = imageUrl.replace('.image/', '.image/c_fill,h_70,w_70/');
        let hires = imageUrl.replace('.image/', '.image/c_fill,h_140,w_140/');
        let srcset = `${lores}, ${hires} 2x`;
        item['src'] = lores;
        item['srcset'] = srcset;
    });
}

function inferSlugs(items) {
    items.forEach((item) => {
        item.slug = item.url.split('/').slice(-1);
    });
}

$p('setRequestFields', ['_id', 'thumbnail', 'title', 'site_keyword']);
var rendered_li_recs = [];
var reqCount = 20;
var remaining_articles = true;
var remaining_recent_articles = true;
var site_keywords = [];
if (typeof siteKeyword !== 'undefined') {
    site_keywords.push(siteKeyword);
} else {
    for (site of rojoSites) {
        site_keywords.push(site.siteKeyword);
    }
}
var opts = {
    site_keyword: site_keywords,
    maxAgeInSeconds: 60 * 60 * 24 * 5,
    excludeItems: rendered_li_recs,
};

function getPage() {
    $p('register', {
        max: reqCount,
        opts: opts,
        widget: 'infinite-scroll',
        callback: function (resp) {
            if (resp.items.length == 0 && !remaining_recent_articles) {
                remaining_articles = false;
            }
            scaleImages(resp.items);
            inferSlugs(resp.items);
            var el = document.querySelector('#li-recommendation-unit');
            var template = document.querySelector('#li-recommendation-template').innerHTML;
            el.innerHTML += Mustache.render(template, resp);
            for (item of resp.items) {
                rendered_li_recs.push(item.url);
            }
            if (remaining_recent_articles && resp.items.length < reqCount) {
                remaining_recent_articles = false;
                delete opts.maxAgeInSeconds;
                getPage();
            }
        },
    });
    $p('fetch');
}

getPage();
var liLastLoaded = Date.now();
window.onscroll = function (e) {
    if (remaining_articles && Date.now() - liLastLoaded > 1000) {
        if (document.body.scrollHeight - window.scrollY <= 2 * screen.height) {
            liLastLoaded = Date.now();
            getPage();
        }
    }
};
